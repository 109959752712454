import React from 'react'

import styled, {
  createGlobalStyle,
} from '../context/theme-provider/styled-components'

export interface IProps {
  children?: any
}

const AppWrapper = styled.div`
  background: ${p => p.theme.appBackground};
  margin: 0;
  -webkit-font-smoothing: antialiased;
  min-height: 100%;
`

export const GlobalStyle = createGlobalStyle`
  body {
    min-height: 100%;
    background: ${p => p.theme.appBackground};
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,sans-serif;
    margin: 0;
    -webkit-font-smoothing: antialiased;
  }
`

export const GlobalAppWrapper = (props: any) => {
  return (
    <React.Fragment>
      <GlobalStyle />
      <AppWrapper {...props}>{props.children}</AppWrapper>
    </React.Fragment>
  )
}
