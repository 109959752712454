import React, { useReducer } from 'react'

import EN from './en.json'
import DE from './de.json'
import ES from './es.json'

// To make it easier to read from JSON files
const translations: any = {
  en: EN,
  de: DE,
  es: ES,
}

const LANG_STORAGE_CONST = 'app_lang'
let initialLanguage = 'de'
// set language to localstorage
const saveLanguageToStorage = (lang: string) => {
  try {
    window.localStorage.setItem(LANG_STORAGE_CONST, lang)
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.log('Local Storage Issue.')
  }
}
// get language to localstorage
const getLanguageFromStorage = (): string => {
  try {
    const lang = window.localStorage.getItem(LANG_STORAGE_CONST)
    return lang !== null ? lang : initialLanguage
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.log('Local Storage Issue.')
    return initialLanguage
  }
}

// This function will be used to create `translate` function for the context
const getTranslate = (langCode: string) => (key: string): string =>
  translations[langCode][key] || key

/* We will have two things in our context state,
langCode will be the current language of the page
and translate will be the method to translate keys
into meaningful texts. Default language will be English */

interface IStateType {
  langCode: string
  translate: (key: string) => string
}

interface IActionType {
  type: string
  payload: any
}

interface IContextProps {
  dispatch: ({ type, payload }: { type: string; payload: any }) => void
}

initialLanguage = getLanguageFromStorage()
const initialState = {
  langCode: initialLanguage,
  translate: getTranslate(initialLanguage),
}

export const I18nContext = React.createContext(initialState as IContextProps &
  IStateType)

const I18nContextProvider = ({ children }: any) => {
  /* This is where magic starts to happen. We're creating
  a reducer to manage the global state which will sit in
  I18nContext. For now, the only action we will have
  is setting language */
  // tslint:disable-next-line:no-shadowed-variable
  const reducer = (state: IStateType, action: IActionType) => {
    switch (action.type) {
      case 'setLanguage':
        saveLanguageToStorage(action.payload)
        return {
          langCode: action.payload,
          translate: getTranslate(action.payload),
        }
      default:
        return { ...initialState }
    }
  }

  /* useReducer hook receives a reducer and an initialState to
  return the current state object with a dispatch method to
  dispatch actions. */
  const [state, dispatch] = useReducer(reducer, initialState)

  /* We're Providing state object (langCode and translate method
  in this case) and also the dispatch for the children components */
  return (
    <I18nContext.Provider value={{ ...state, dispatch }}>
      {children}
    </I18nContext.Provider>
  )
}

export default I18nContextProvider
