const themes = {
  dark: {
    /* App fundamentals */
    appBackground: 'rgba(24, 24, 24)',

    /* Typo & Content */
    appTitle: 'rgba(255, 255, 255)',
    appSubTitle: 'rgba(212, 212, 212)',
    appContext: 'rgba(255, 255, 255, 0.6)',

    /* Brands */
    appBrandGradient1: 'rgba(243, 128, 56)',
    appBrandGradient2: 'rgba(255, 67, 149)',

    /* Buttons */
    appPrimary: 'rgba(41, 42, 45)',
    appPrimaryHover: 'rgba(243, 128, 56)',
    appPrimaryBorder: 'rgba(243, 128, 56, 0.3)',
    appSecondary: 'rgba(192, 115, 202)',

    appAttention: 'rgba(64, 66, 78)',
    appSuccess: 'rgba(255, 67, 149)',

    appClickMe: 'rgb(86, 204, 242)',
    appGlobalSwitch: 'rgb(86, 204, 242)',
    appGlobalSwitchContrast: 'rgba(255, 67, 149)',
    appBlack: 'rgb(0, 0, 0)',
    appWhite: 'rgb(255, 255, 255)',

    /* App navigation */
    naviBackground: 'rgba(0, 0, 0, 0.8)',
    naviBackgroundHover: 'rgba(255, 255, 255)',
    naviLink: 'rgba(200, 200, 200)',
    naviLinkLogin: 'rgba(255, 255, 255)',
    naviLinkHover: 'rgba(24, 24, 24)',
    naviLinkMarked: 'rgb(86, 204, 242)',

    /* Panel */
    panelBackground: 'rgba(55, 60, 65, 0.9)',
    panelWrapperBackground: 'rgba(255, 255, 255, 0.8)',
    panelBoxShadow: 'rgba(0, 0, 0, 0.25)',

    /* Modal */
    modalBackDropColor: 'rgba(0, 0, 0, 0.8)',
    modalBackgroundColor: 'rgba(255, 255, 255)',

    /* Card */
    cardDropShadow: 'rgba(0, 0, 0, 0.25) 0px 10px 20px',
    cardDropShadowHover: 'rgba(0, 0, 0, 0.5) 0px 30px 60px',
    cardBg: 'rgba(55, 60, 65, 0.8)',
    cardGradient:
      'linear-gradient(95.65deg, rgba(21, 21, 21, 0.7) 13.2%, rgba(21, 21, 21, 0.7) 55.88%, rgba(55, 60, 65, 0.8) 97.88%)',

    /* Loading */
    loadingColor: 'rgba(1, 241, 249)',

    /* canvasGradientColors */
    canvasGradientColor1: 'rgba(52, 31, 73)',
    canvasGradientColor2: 'rgba(255, 67, 149)',
    canvasGradientColor3: 'rgba(243, 128, 56)',
    canvasGradientColor4: 'rgba(239, 226, 4)',
  },
  light: {
    /* App fundamentals */
    appBackground: 'rgba(255, 255, 255)',

    /* Typo & Content */
    appTitle: 'rgba(24, 24, 24)',
    appSubTitle: 'rgba(175, 177, 179)',
    appContext: 'rgba(24, 24, 24)',

    /* Brands */
    appBrandGradient1: 'rgba(1, 241, 249)',
    appBrandGradient2: 'rgba(0, 172, 225)',

    /* Buttons */
    appPrimary: 'rgba(112, 122, 237)',
    appSecondary: 'rgba(192, 115, 202)',
    appAttention: 'rgba(239, 118, 110)',
    appSuccess: 'rgba(83, 168, 142)',
    appInfo: 'rgba(214, 147, 54)',
    appClickMe: 'rgba(86, 204, 242)',
    appGlobalSwitch: 'rgba(255, 67, 149)',
    appGlobalSwitchContrast: 'rgb(86, 204, 242)',
    appBlack: 'rgb(0, 0, 0)',
    appWhite: 'rgb(255, 255, 255)',

    /* App navigation */
    naviBackground: 'rgba(0, 0, 0, 0.8)',
    naviBackgroundHover: 'rgba(0, 0, 0, 0.8)',
    naviLink: 'rgba(24, 24, 24)',
    naviLinkLogin: 'rgba(255, 255, 255)',
    naviLinkHover: 'rgb(0, 0, 0)',
    naviLinkMarked: 'rgb(86, 204, 242)',

    /* Panel */
    panelBackground: 'rgba(209, 218, 213))',
    panelWrapperBackground: 'rgba(4, 8, 37, 0.8)',
    panelBoxShadow: 'rgba(0, 0, 0, 0.25)',

    /* Modal */
    modalBackDropColor: 'rgba(38, 38, 38, 0.85)',
    modalBackgroundColor: 'rgba(209, 218, 213)',

    /* Card */
    cardDropShadow: 'rgba(0, 0, 0, 0.25) 0px 10px 20px',
    cardDropShadowHover: 'rgba(0, 0, 0, 0.5) 0px 30px 60px',
    cardBg: 'rgba(255, 255, 255, 0.8)',
    cardGradient:
      'linear-gradient(95.65deg, rgba(255, 255, 255, 0.8) 13.2%, rgba(255, 255, 255, 0.7) 55.88%, rgba(255, 255, 255, 0.75) 97.88%)',

    /* Loading */
    loadingColor: 'rgba(19, 167, 199)',

    /* canvasGradientColors */
    canvasGradientColor1: 'rgba(1, 241, 249)',
    canvasGradientColor2: 'rgba(0, 172, 225)',
    canvasGradientColor3: 'rgba(0, 112, 240)',
    canvasGradientColor4: 'rgba(239, 226, 4)',
  },
}

export default themes
