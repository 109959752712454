import appInit from './app-init'

// just for debug purpose to see which environment we are running

console.log(
  'ENV',
  process.env.REACT_APP_ENV,
  process.env.REACT_APP_FIREBASE_KEY,
  process.env.REACT_APP_FIREBASE_DOMAIN
)

appInit()
