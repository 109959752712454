import React, { useState } from 'react'

interface IThemeNameContext {
  themeName: string
  toggleTheme: () => void
}

let initialTheme = 'dark'

const THEME_STORAGE_CONST = 'namic_theme'

// set theme to localstorage
const saveThemeToStorage = (theme: string) => {
  try {
    window.localStorage.setItem(THEME_STORAGE_CONST, theme)
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.log('Local Storage Issue.')
  }
}
// get theme to localstorage
const getThemeFromStorage = (): string => {
  try {
    const theme = window.localStorage.getItem(THEME_STORAGE_CONST)
    return theme !== null ? theme : initialTheme
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.log('Local Storage Issue.')
    return initialTheme
  }
}

initialTheme = getThemeFromStorage()

const initialState = {
  themeName: initialTheme,
}

export const ThemeNameContext = React.createContext(
  initialState as IThemeNameContext
)

const ThemeNameProvider = ({ children }: any) => {
  const [themeName, setThemeName] = useState<string>(initialState.themeName)

  const toggleTheme = () => {
    saveThemeToStorage(themeName === 'light' ? 'dark' : 'light')
    setThemeName(themeName === 'light' ? 'dark' : 'light')
  }

  return (
    <ThemeNameContext.Provider value={{ themeName, toggleTheme }}>
      {children}
    </ThemeNameContext.Provider>
  )
}

export default ThemeNameProvider
