import React, { useReducer } from 'react'
import app from '../../firebase'

interface IStateType {
  user: object | null
  userLoading: boolean
  logoutRedirectUrl?: string | null
}

interface IActionType {
  type: string
  payload?: any
}

interface IContextProps {
  loginUser: (user: any) => void
  logoutUser: (redirectUrl?: string | null | undefined) => void
  setLogoutRedirectUrl: (url: string) => void
  setUserLoading: (flag: boolean) => void
  user: any | null
  logoutRedirectUrl?: string | null
  userLoading: boolean
}

const initialState = {
  user: null,
  userLoading: true,
  logoutRedirectUrl: null,
}

export const UserContext = React.createContext(initialState as IContextProps &
  IStateType)

const UserContextProvider = ({ children }: any) => {
  const reducer = (givenState: IStateType, action: IActionType) => {
    switch (action.type) {
      case 'loginUser':
        return {
          ...givenState,
          user: action.payload,
        }
      case 'setUserLoading':
        return {
          ...givenState,
          userLoading: action.payload,
        }
      case 'logoutUser':
        return {
          ...givenState,
          user: null,
          logoutRedirectUrl: action.payload,
        }
      case 'setLogoutRedirectUrl':
        return {
          ...givenState,
          logoutRedirectUrl: action.payload,
        }
      default:
        return givenState
    }
  }

  /* useReducer hook receives a reducer and an initialState to
  return the current state object with a dispatch method to
  dispatch actions. */
  const [state, dispatch] = useReducer(reducer, initialState)
  const loginUser = user => dispatch({ type: 'loginUser', payload: user })
  const setUserLoading = (flag: boolean) =>
    dispatch({ type: 'setUserLoading', payload: flag })
  const setLogoutRedirectUrl = url =>
    dispatch({ type: 'setLogoutRedirectUrl', payload: url })
  const logoutUser = (redirectUrl: string | null | undefined = null) => {
    setUserLoading(true)
    app
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: 'logoutUser', payload: redirectUrl })
        setUserLoading(false)
      })
  }

  /* We're Providing state object (langCode and translate method
  in this case) and also the dispatch for the children components */
  return (
    <UserContext.Provider
      value={{
        user: state.user,
        loginUser,
        userLoading: state.userLoading,
        setUserLoading,
        setLogoutRedirectUrl,
        logoutUser,
        logoutRedirectUrl: state.logoutRedirectUrl,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserContextProvider
