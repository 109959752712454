import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'

// context
import I18nContextProvider from './context/i18n'
import UserContextProvider from './context/user-indentity-provider'
import ThemeNameProvider from './context/theme-provider/theme-name-provider'

// component
import { App } from './components/App'

const appRender = () => {
  ReactDOM.render(
    <ThemeNameProvider>
      <UserContextProvider>
        <I18nContextProvider>
          <Suspense fallback={null}>
            <App />
          </Suspense>
        </I18nContextProvider>
      </UserContextProvider>
    </ThemeNameProvider>,
    document.getElementById('root')
  )
}

export default () => {
  appRender()
}

serviceWorker.unregister()
