import React, { useContext, useEffect, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import media from '../../css/mediaQueries'
import { I18nContext } from '../../context/i18n'
import { Link } from 'react-navi'

const COOKIE_NOTICE_CONST = 'namic_cookie_notice'

const moveInFromBottom = keyframes`
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
`

const moveToBottom = keyframes`
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(100%);
  }
`

const Wrapper = styled.div<{ accepted: boolean }>`
  width: 360px;
  padding: 0 0 24px 24px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: left;
  animation: ${p =>
      p.accepted
        ? css`
            ${moveToBottom}
          `
        : css`
            ${moveInFromBottom}
          `}
    0.5s ease;
  animation-fill-mode: forwards;

  ${media.screenXsMin`
     color: 100%;
  `}
`

const WrapperInner = styled.div`
  background: ${p => p.theme.panelBackground};
  box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 20px;
  transition: box-shadow 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
  padding: 0.5rem 1rem;
  line-height: normal;
  overflow: hidden;
  flex-direction: column;
  border-radius: 16px;
  overflow: hidden;
  :hover {
    box-shadow: ${p => p.theme.cardDropShadowHover};
  }
`
const CookieContext = styled.div`
  font-family: Roboto;
  color: ${p => p.theme.appTitle};
  font-size: 14px;
  padding: 0px 0px 16px 0px;
  display: flex;
  line-height: 1.5;
`

const GriItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  cursor: pointer;
  gap: 40px 40px;
`

const CloseButton = styled.button`
  width: auto;
  padding: 0;
  cursor: pointer;
  color: ${p => p.theme.naviLinkLogin};
  background-color: transparent;
  border: 0;
  font-size: 14px;
`

interface LinkProps {
  href?: string
  anchor?: boolean
}

const MoreRedirect = styled(Link).attrs<LinkProps>({
  href: ({ href }: LinkProps) => href || '#',
  target: ({ anchor }: LinkProps) => (anchor ? '_self' : '_blank'),
})`
  padding: 0;
  cursor: pointer;
  color: ${p => p.theme.naviLinkLogin};
  background-color: transparent;
  border: 0;
  font-size: 14px;
  text-decoration: none;
`

const enableCookiesConfirmed = document.cookie.indexOf(COOKIE_NOTICE_CONST) > -1

const CookieNotice = () => {
  const [cookiesEnabled, setCookiesEnabled] = useState<boolean>(false)
  const { translate } = useContext(I18nContext)

  useEffect(() => {
    if (cookiesEnabled) {
      const nextDate = new Date()
      nextDate.setFullYear(nextDate.getFullYear() + 1)
      document.cookie = `${COOKIE_NOTICE_CONST}=true;expires=${nextDate.toUTCString()};path=/;`
    }
  }, [cookiesEnabled])

  if (enableCookiesConfirmed) return null

  return (
    <Wrapper data-testid="cookie-notice" accepted={cookiesEnabled}>
      <WrapperInner>
        <CookieContext>{translate('cookieNotice.content')}</CookieContext>
        <GriItem>
          <MoreRedirect href="/contact" target="_self">
            {translate('cookieNotice.label.moreInfo')}
          </MoreRedirect>
          <CloseButton onClick={() => setCookiesEnabled(true)}>
            {translate('cookieNotice.label.acceptBtn')}
          </CloseButton>
        </GriItem>
      </WrapperInner>
    </Wrapper>
  )
}

export default CookieNotice
