import { mount, route, redirect, map, lazy } from 'navi'
import React from 'react'
import { useNavigation } from 'react-navi'

// protected routes when user login then only allowed to procced
const loginProtectedRoute = givenRoute =>
  map(async (request, context: any) => {
    if (!context.user) {
      console.log(context)
      if (context.logoutRedirectUrl) {
        const rediectUrl = context.logoutRedirectUrl
        setTimeout(() => {
          context.setLogoutRedirectUrl(null)
        }, 1500)
        return redirect(rediectUrl)
      }
      return redirect('/login')
    } else {
      return givenRoute
    }
  })

// no login route / public route only works when user is NOT logged in
const onlyWithoutLoginRoute = givenRoute =>
  map(async (request, context: any) => {
    if (context.user) {
      return redirect('/profile')
    } else {
      return givenRoute
    }
  })

export default mount({
  // public route login or no login it will allow
  '/': route({
    getView: async () => {
      // This simulates some async content loading, so that
      // we can test the site's loading bar.
      await new Promise(resolve => setTimeout(resolve, 1000))

      return import('../pages/home')
    },
    head: <meta name="description" content="námic.io development" />,
    title: 'námic.io',
  }),

  '/whatwedo': route({
    getView: async () => {
      // This simulates some async content loading, so that
      // we can test the site's loading bar.
      await new Promise(resolve => setTimeout(resolve, 100000))

      return import('../pages/whatwedo')
    },
    head: <meta name="description" content="whatwedo" />,
    title: 'whatwedo',
  }),

  '/work': route({
    getView: async () => {
      // This simulates some async content loading, so that
      // we can test the site's loading bar.
      await new Promise(resolve => setTimeout(resolve, 1000))

      return import('../pages/work')
    },
    head: <meta name="description" content="work" />,
    title: 'Work',
  }),

  '/resources': route({
    getView: async () => {
      // This simulates some async content loading, so that
      // we can test the site's loading bar.
      await new Promise(resolve => setTimeout(resolve, 1000))

      return import('../pages/resources')
    },
    head: <meta name="description" content="Resources" />,
    title: 'Resources',
  }),

  '/contact': route({
    getView: async () => {
      // This simulates some async content loading, so that
      // we can test the site's loading bar.
      await new Promise(resolve => setTimeout(resolve, 1000))

      return import('../pages/contact')
    },
    head: <meta name="contact" content="contact" />,
    title: 'Contact',
  }),

  // Miscellaneous pages can be added directly to the root switch.
  '/about': lazy(() => import('../pages/about/')),

  // no login route / public route only works when user is NOT logged in
  '/login': onlyWithoutLoginRoute(
    route({
      getView: async (req, context: any) => {
        const { default: Login } = await import('../pages/login')
        return <Login app={context.app} />
      },
      title: 'Profile',
    })
  ),

  '/register': onlyWithoutLoginRoute(
    route({
      getView: async (req, context: any) => {
        const { default: Login } = await import('../pages/login')
        return <Login app={context.app} register />
      },
      title: 'Registration',
    })
  ),

  // protected routes when user login then only allowed to procced
  '/logout': loginProtectedRoute(
    route({
      getView: async (req, context: any) => {
        return () => {
          const navigation = useNavigation()
          setTimeout(() => {
            context.logoutUser()
            navigation.navigate('/')
          }, 1500)
          return <h1>Logging You out</h1>
        }
      },
      title: 'Logout',
    })
  ),

  '/profile': loginProtectedRoute(
    route({
      getView: async () => {
        return import('../pages/profile')
      },
      title: 'Profile',
    })
  ),

  '/inquiry': loginProtectedRoute(
    route({
      getView: async () => {
        return import('../pages/inquiry')
      },
      title: 'Inquiry',
    })
  ),

  '/portfolio': route({
    getView: async () => {
      return import('../pages/portfolio')
    },
    title: 'Portfolio',
  }),
})
